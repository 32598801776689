import PropTypes from "prop-types";
import { useContext } from "react";
import { AppContext } from "../../../pages/root/Root";

import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ListItemText,
  OutlinedInput,
  Checkbox,
} from "@mui/material/";

import { Search } from "@mui/icons-material";

import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { initLocalization } from "../../../utils/helpers";
import { monitoringFiltersStrings } from "./locale";
import {
  MAPPING_KEYS_API_DATE_FORMAT,
  START_DATE,
  END_DATE,
  SEARCH_FIELD_MIN_CHARS,
} from "../../../constants";

export const MonitoringFilters = ({ filters, setFilters, onSearch }) => {
  initLocalization(monitoringFiltersStrings);

  const { language } = useContext(AppContext);

  const disableSearch = () => {
    return (
      filters.view === "advertiser" &&
      filters.advertiserLabel !== "" &&
      filters.advertiserLabel.trim().length < SEARCH_FIELD_MIN_CHARS
    );
  };

  const handleSetFilters = (event) => {
    setFilters((prev) => {
      let next = {
        ...prev,
        [event.target.name]: event.target.value,
      };

      if (event.target.name === "view") {
        next.advertiserLabel = "";
      }

      if (event.target.name === "period") {
        next.startDate = null;
        next.endDate = null;
      }

      return next;
    });
  };

  const handleSelecteDevice = (event) => {
    const devices = event.target.value;

    setFilters({
      ...filters,
      devices: devices,
    });
  };

  const handleFieldKeydown = (event) => {
    if (disableSearch()) {
      return;
    }

    if (event.key === "Enter" && event.target.value) {
      event.stopPropagation();
      onSearch();
    }
  };

  const handleDateChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: dayjs(value)
        .startOf("month")
        .format(MAPPING_KEYS_API_DATE_FORMAT),
    });
  };

  return (
    <Grid container className="creative-review-filters">
      <Grid
        xs={12}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <Box
          sx={{
            "&": {
              flex: "1",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="view-label">
              {monitoringFiltersStrings.view}
            </InputLabel>
            <Select
              labelId="view-label"
              id="view-select"
              value={filters.view}
              label="View"
              onChange={handleSetFilters}
              name="view"
            >
              <MenuItem value="media">
                {monitoringFiltersStrings.media}
              </MenuItem>
              <MenuItem value="advertiser">
                {monitoringFiltersStrings.advertiser}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            "&": {
              flex: "1",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="unit-label">
              {monitoringFiltersStrings.unit}
            </InputLabel>
            <Select
              labelId="unit-label"
              id="unit-select"
              value={filters.unit}
              label="Unit"
              onChange={handleSetFilters}
              name="unit"
            >
              <MenuItem value="Occurrences">
                {monitoringFiltersStrings.occurrences}
              </MenuItem>
              <MenuItem value="CreativeClassified">
                {monitoringFiltersStrings.creativeClassified}
              </MenuItem>
              <MenuItem value="CreativeRejected">
                {monitoringFiltersStrings.creativeRejected}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            "&": {
              flex: "1",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="devices-label">
              {monitoringFiltersStrings.devices}
            </InputLabel>
            <Select
              labelId="devices-label"
              id="devices-select"
              multiple
              value={filters.devices}
              onChange={handleSelecteDevice}
              input={<OutlinedInput label={monitoringFiltersStrings.devices} />}
              renderValue={(selected) => (
                <Typography>
                  {selected
                    .map((sel) => monitoringFiltersStrings[sel])
                    .join(", ")}
                </Typography>
              )}
            >
              <MenuItem value="Desktop">
                <Checkbox checked={filters.devices.includes("Desktop")} />
                <ListItemText primary={monitoringFiltersStrings.Desktop} />
              </MenuItem>
              <MenuItem value="Youtube Desktop">
                <Checkbox
                  checked={filters.devices.includes("Youtube Desktop")}
                />
                <ListItemText
                  primary={monitoringFiltersStrings["Youtube Desktop"]}
                />
              </MenuItem>
              <MenuItem value="Mobile">
                <Checkbox checked={filters.devices.includes("Mobile")} />
                <ListItemText primary={monitoringFiltersStrings.Mobile} />
              </MenuItem>
              <MenuItem value="Youtube Mobile">
                <Checkbox
                  checked={filters.devices.includes("Youtube Mobile")}
                />
                <ListItemText
                  primary={monitoringFiltersStrings["Youtube Mobile"]}
                />
              </MenuItem>
              <MenuItem value="Application">
                <Checkbox checked={filters.devices.includes("Application")} />
                <ListItemText primary={monitoringFiltersStrings.Application} />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            "&": {
              flex: "1",
            },
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="period-label">
              {monitoringFiltersStrings.period}
            </InputLabel>
            <Select
              labelId="period-label"
              id="period-select"
              value={filters.period}
              label="Period"
              onChange={handleSetFilters}
              name="period"
            >
              <MenuItem value="Day">{monitoringFiltersStrings.day}</MenuItem>
              <MenuItem value="Month">
                {monitoringFiltersStrings.month}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DatePicker
            label={monitoringFiltersStrings.startDate}
            views={["year", "month"]}
            format="MMM YYYY"
            value={
              dayjs(filters[START_DATE]).isValid()
                ? dayjs(filters[START_DATE])
                : null
            }
            minDate={dayjs("2024-07")}
            maxDate={
              dayjs(filters[END_DATE]).isValid()
                ? dayjs(filters[END_DATE])
                : dayjs(new Date()).endOf("month")
            }
            onChange={(value) => handleDateChange(value, START_DATE)}
            disabled={filters.period !== "Month"}
            slotProps={{ textField: { size: "small" } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
          />

          <DatePicker
            label={monitoringFiltersStrings.endDate}
            views={["year", "month"]}
            format="MMM YYYY"
            value={
              dayjs(filters[END_DATE]).isValid()
                ? dayjs(filters[END_DATE])
                : null
            }
            minDate={
              dayjs(filters[START_DATE]).isValid()
                ? dayjs(filters[START_DATE])
                : dayjs("2024-07")
            }
            maxDate={dayjs(new Date()).endOf("month")}
            onChange={(value) => handleDateChange(value, END_DATE)}
            disabled={filters.period !== "Month"}
            slotProps={{ textField: { size: "small" } }}
            sx={{
              "&": { flex: "1" },
              "& .MuiFormLabel-root": { maxWidth: "calc(100% - 2.875rem)" },
              "& .MuiFormLabel-root.MuiFormLabel-filled, & .MuiFormLabel-root.Mui-focused":
                {
                  maxWidth: "calc(133% - 2rem)",
                },
            }}
          />
        </LocalizationProvider>

        <Box
          sx={{
            "&": { flex: 1 },
            "& .MuiTextField-root": { width: "100%" },
          }}
        >
          <TextField
            label={monitoringFiltersStrings.advertiser}
            size="small"
            name="advertiserLabel"
            value={filters.advertiserLabel}
            onChange={handleSetFilters}
            onKeyDown={handleFieldKeydown}
            disabled={filters.view !== "advertiser"}
          />
        </Box>

        <Button
          startIcon={<Search />}
          variant="contained"
          color="secondary"
          onClick={onSearch}
          disabled={disableSearch()}
        >
          {monitoringFiltersStrings.search}
        </Button>
      </Grid>
    </Grid>
  );
};

MonitoringFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
