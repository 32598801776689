import LocalizedStrings from "react-localization";

export const monitoringFiltersStrings = new LocalizedStrings({
  en: {
    view: "View",
    media: "Media",
    advertiser: "Advertiser",
    unit: "Unit",
    occurrences: "Occurrences",
    creativeClassified: "Creative classified",
    creativeRejected: "Creative rejected",
    devices: "Devices",
    Desktop: "Desktop",
    "Youtube Desktop": "Youtube Desktop",
    Mobile: "Mobile",
    "Youtube Mobile": "Youtube Mobile",
    Application: "Application",
    period: "Period",
    day: "Day",
    month: "Month",
    startDate: "Start date",
    endDate: "End date",
    search: "Apply filters",
  },
  fr: {
    view: "View",
    media: "Média",
    advertiser: "Annonceur",
    unit: "Unit",
    occurrences: "Occurrences",
    creativeClassified: "Créatif classifié",
    creativeRejected: "Créatif rejeté",
    devices: "Devices",
    Desktop: "Desktop",
    "Youtube Desktop": "Youtube Desktop",
    Mobile: "Mobile",
    "Youtube Mobile": "Youtube Mobile",
    Application: "Application",
    period: "Period",
    day: "Jour",
    month: "Mois",
    startDate: "Date de début",
    endDate: "Date de fin",
    search: "Appliquer des filtres",
  },
});
