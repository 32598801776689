import { useState } from "react";
import Api from "../../utils/Api";
import {
  API_ROUTE_BIS_MONITORING_MEDIA,
  API_ROUTE_BIS_MONITORING_ADVERTISER,
  API_ROUTE_BIS_MONITORING_MEDIA_EXPORT,
  API_ROUTE_BIS_MONITORING_ADVERTISER_EXPORT,
} from "../../routes";
import { debug } from "../../utils/helpers";
import {
  DEFAULT_MONITORING_MEDIA_PAYLOAD,
  DEFAULT_MONITORING_ADVERTISER_PAYLOAD,
} from "../../constants";

const buildPayload = (schema, filters) => {
  const payload = {};

  Object.keys(schema).forEach((key) => {
    payload[key] = filters[key];
  });

  return payload;
};

const transformResponse = (response) => {
  const { data, ...pagination } = response;

  const newData = data.map((entry) => {
    const series = {};
    const { aggregateValues, ...rest } = entry;

    const flattenedValues = aggregateValues.map((value) => {
      series[value.header?.toLowerCase()] = value.translatedHeader;
      return [value.header?.toLowerCase(), value.totalCount];
    });

    return { series, ...Object.fromEntries(flattenedValues), ...rest };
  });

  return { data: newData, ...pagination };
};

export default function useMonitoringView() {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [mediaList, setMediaList] = useState([]);
  const [advertiserList, setAdvertiserList] = useState([]);

  const resetLists = () => {
    setMediaList([]);
    setAdvertiserList([]);
  };

  const getMediaList = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(
      API_ROUTE_BIS_MONITORING_MEDIA,
      buildPayload(DEFAULT_MONITORING_MEDIA_PAYLOAD, payload),
    )
      .then((response) => {
        setMediaList(transformResponse(response.data));
      })
      .catch((error) => {
        debug.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAdvertiserList = (payload = {}, showLoader = true) => {
    setIsLoading(showLoader);

    return Api.post(
      API_ROUTE_BIS_MONITORING_ADVERTISER,
      buildPayload(DEFAULT_MONITORING_ADVERTISER_PAYLOAD, payload),
    )
      .then((response) => {
        setAdvertiserList(transformResponse(response.data));
      })
      .catch((error) => {
        debug.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportMediaList = (payload = {}) => {
    setIsExporting(true);

    return Api.post(
      API_ROUTE_BIS_MONITORING_MEDIA_EXPORT,
      buildPayload(DEFAULT_MONITORING_MEDIA_PAYLOAD, payload),
      { responseType: "blob" },
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(async (error) => {
        debug.error(error);
        const err = JSON.parse(await error?.response?.data.text());
        return Promise.reject(err?.errors?.SearchFilter);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const exportAdvertiserList = (payload = {}) => {
    setIsExporting(true);

    return Api.post(
      API_ROUTE_BIS_MONITORING_ADVERTISER_EXPORT,
      buildPayload(DEFAULT_MONITORING_ADVERTISER_PAYLOAD, payload),
      { responseType: "blob" },
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(async (error) => {
        debug.error(error);
        const err = JSON.parse(await error?.response?.data.text());
        return Promise.reject(err?.errors?.SearchFilter);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return {
    isLoading,
    isExporting,
    mediaList,
    advertiserList,
    getMediaList,
    getAdvertiserList,
    exportMediaList,
    exportAdvertiserList,
    resetLists,
  };
}
