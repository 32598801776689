import LocalizedStrings from "react-localization";

export const monitoringStrings = new LocalizedStrings({
  en: {
    title: "Monitoring",
    exportButton: "Export",
    resetFiltersButton: "Reset",
    media: "Media",
    advertiser: "Advertiser",
    mediaId: "Kantar Media ID",
    mediaName: "Kantar Media Name",
    advertiserLabel: "Kantar Advertiser Label",
  },
  fr: {
    title: "Monitoring",
    exportButton: "Exporter",
    resetFiltersButton: "Réinitialiser",
    media: "Média",
    advertiser: "Annonceur",
    mediaId: "Kantar ID Média",
    mediaName: "Kantar Nom du Média",
    advertiserLabel: "Kantar Libellé Annonceur",
  },
});
