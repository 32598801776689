import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import {
  PersonSearchOutlined as PersonSearchIcon,
  HourglassBottomOutlined as HourglassBottomIcon,
  LayersOutlined as LayersIcon,
  VisibilityOutlined as VisibilityIcon,
  SettingsOutlined as SettingsIcon,
  Logout as LogoutIcon,
  Close as CloseIcon,
  PermMedia as PermMediaIcon,
  ExpandMore as ExpandMoreIcon,
  MonitorHeartOutlined as MonitorIcon,
} from "@mui/icons-material";
import { AppContext } from "../../../pages/root/Root";
import { initLocalization } from "../../../utils/helpers";
import {
  ROUTE_ADMIN,
  ROUTE_LOGIN,
  ROUTE_MAIN,
  ROUTE_PATHMATICS_LEVEL2,
  ROUTE_PATHMATICS_LEVEL3,
  ROUTE_BIS_LEVEL1,
  ROUTE_BIS_LEVEL2,
  ROUTE_BIS_LEVEL3,
  ROUTE_BIS_MONITORING,
  ROUTE_MAPPINGS_VIEW,
  API_ROUTE_LOG_OUT,
  API_ROUTE_MAPPING_UNLOCK_ALL_LINES,
} from "../../../routes";
import { MENU_SECTIONS } from "../../../constants";
import Api from "../../../utils/Api";
import { burgerMenuStrings } from "./locale";
import "./style.scss";

export const BurgerMenu = ({ open, onClose }) => {
  initLocalization(burgerMenuStrings);

  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useContext(AppContext);
  const [expanded, setExpanded] = useState("");

  const pathname = location.pathname.split("/")?.[1];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleLogout = async () => {
    await Api.patch(API_ROUTE_MAPPING_UNLOCK_ALL_LINES);
    await Api.post(API_ROUTE_LOG_OUT);
    navigate(ROUTE_LOGIN, { replace: true });
  };

  return (
    <Drawer
      className="burger-menu"
      open={open}
      elevation={3}
      hideBackdrop={true}
      onClose={onClose}
      data-testid="burger-menu-list"
      disableEnforceFocus
    >
      <List
        subheader={
          <ListSubheader className="burger-menu__header" sx={{ p: 1 }}>
            <IconButton
              onClick={onClose}
              aria-label={burgerMenuStrings.closeMenu}
              data-testid="close-burger-menu"
            >
              <CloseIcon color="primary" sx={{ width: 20, height: 20 }} />
            </IconButton>
          </ListSubheader>
        }
      >
        <Accordion
          className="burger-menu__section"
          expanded={expanded === MENU_SECTIONS.pathmatics}
          onChange={handleChange(MENU_SECTIONS.pathmatics)}
        >
          <AccordionSummary
            className={`burger-menu__title ${
              pathname === MENU_SECTIONS.pathmatics || pathname === ""
                ? "selected"
                : ""
            }`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${MENU_SECTIONS.pathmatics}-content`}
            id={`${MENU_SECTIONS.pathmatics}-header`}
          >
            <ListItem>
              <Grid display="flex" flexDirection="row" gap={0.5} pt={1}>
                <ListItemIcon>
                  <PersonSearchIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "& p": {
                      fontWeight: "bold",
                      fontSize: "1rem",
                    },
                  }}
                >
                  {burgerMenuStrings.paidSocialCategory}
                </ListItemText>
              </Grid>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className="burger-menu__element">
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_MAIN}
                data-testid="pathmatics-level1-link"
              >
                <ListItemIcon>
                  <LayersIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.mappingLevel}</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_PATHMATICS_LEVEL2}
                data-testid="pathmatics-level2-link"
              >
                <ListItemIcon>
                  <LayersIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>
                  {burgerMenuStrings.creativeClassification}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_PATHMATICS_LEVEL3}
                data-testid="pathmatics-level3-link"
              >
                <ListItemIcon>
                  <VisibilityIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.creativeReview}</ListItemText>
              </ListItemButton>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </List>
      <Divider />
      <List>
        <Accordion
          className="burger-menu__section"
          expanded={expanded === MENU_SECTIONS.bis}
          onChange={handleChange(MENU_SECTIONS.bis)}
        >
          <AccordionSummary
            className={`burger-menu__title ${
              pathname === MENU_SECTIONS.bis ? "selected" : ""
            }`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${MENU_SECTIONS.bis}-content`}
            id={`${MENU_SECTIONS.bis}-header`}
          >
            <ListItem>
              <Grid display="flex" flexDirection="row" gap={0.5} paddingTop={1}>
                <ListItemIcon>
                  <HourglassBottomIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "& p": {
                      fontWeight: "bold",
                      fontSize: "1rem",
                    },
                  }}
                >
                  {burgerMenuStrings.displayCategory}
                </ListItemText>
              </Grid>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className="burger-menu__element">
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_BIS_LEVEL1}
                data-testid="bis-level1-link"
              >
                <ListItemIcon>
                  <LayersIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.mappingLevel}</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_BIS_LEVEL2}
                data-testid="bis-level2-link"
              >
                <ListItemIcon>
                  <LayersIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>
                  {burgerMenuStrings.creativeClassification}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_BIS_LEVEL3}
                data-testid="bis-level3-link"
              >
                <ListItemIcon>
                  <VisibilityIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.creativeReview}</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_BIS_MONITORING}
                data-testid="bis-monitoring-link"
              >
                <ListItemIcon>
                  <MonitorIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.monitoring}</ListItemText>
              </ListItemButton>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </List>
      <Divider />
      <List>
        <Accordion
          className="burger-menu__section"
          expanded={expanded === MENU_SECTIONS.selfPromotion}
          onChange={handleChange(MENU_SECTIONS.selfPromotion)}
        >
          <AccordionSummary
            className={`burger-menu__title ${
              pathname === MENU_SECTIONS.selfPromotion ? "selected" : ""
            }`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${MENU_SECTIONS.selfPromotion}-content`}
            id={`${MENU_SECTIONS.selfPromotion}-header`}
          >
            <ListItem>
              <Grid display="flex" flexDirection="row" gap={0.5} paddingTop={1}>
                <ListItemIcon>
                  <PermMediaIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "& p": {
                      fontWeight: "bold",
                      fontSize: "1rem",
                    },
                  }}
                >
                  {burgerMenuStrings.selfPromotionCategory}
                </ListItemText>
              </Grid>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className="burger-menu__element">
            <ListItem>
              <ListItemButton
                component={NavLink}
                to={ROUTE_MAPPINGS_VIEW}
                data-testid="media-link"
              >
                <ListItemIcon>
                  <LayersIcon color="secondary" />
                </ListItemIcon>
                <ListItemText>{burgerMenuStrings.mappingsView}</ListItemText>
              </ListItemButton>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </List>
      <Divider />
      <List>
        {isAdmin && (
          <ListItem>
            <ListItemButton
              component={NavLink}
              to={ROUTE_ADMIN}
              data-testid="admin-page-link"
            >
              <ListItemIcon>
                <SettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText>{burgerMenuStrings.admin}</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        <ListItem>
          <ListItemButton onClick={handleLogout} data-testid="log-out-link">
            <ListItemIcon>
              <LogoutIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{burgerMenuStrings.logout}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

BurgerMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
