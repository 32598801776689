import LocalizedStrings from "react-localization";

export const burgerMenuStrings = new LocalizedStrings({
  en: {
    paidSocialCategory: "Paid Social",
    displayCategory: "Display",
    selfPromotionCategory: "Self Promotion",
    mappingLevel: "Mapping",
    creativeClassification: "Creative Classification",
    creativeReview: "Creative Review",
    monitoring: "Monitoring",
    mappingsView: "Mapping",
    admin: "Admin",
    logout: "Log out",
    closeMenu: "Close the menu",
  },
  fr: {
    paidSocialCategory: "Paid Social",
    displayCategory: "Display",
    selfPromotionCategory: "Autopromotion",
    mappingLevel: "Mapping",
    creativeClassification: "Classification Visuelles",
    creativeReview: "Revue Visuelles",
    monitoring: "Monitoring",
    mappingsView: "Mapping",
    admin: "Administration",
    logout: "Déconnection",
    closeMenu: "Fermer le menu",
  },
});
