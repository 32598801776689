import { useState, useContext, useMemo } from "react";
import { AppContext } from "../../../pages/root/Root";

import Grid from "@mui/material/Unstable_Grid2";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { Download, Refresh } from "@mui/icons-material";

import { AppAlert } from "../../common/appAlert/AppAlert";
import { DataTable } from "../../common/dataTable/DataTable";
import { MonitoringFilters } from "../monitoringFilters/MonitoringFillters";

import useMonitoringView from "../../../hooks/bis/useMonitoringView";

import { initLocalization, downloadFile } from "../../../utils/helpers";
import { monitoringStrings } from "./locale";
import {
  DEFAULT_MONITORING_FILTERS,
  DEFAULT_MONITORING_MEDIA_SORTING,
  DEFAULT_MONITORING_ADVERTISER_SORTING,
  DEFAULT_TABLE_PAGINATION,
} from "../../../constants";

export const Monitoring = () => {
  initLocalization(monitoringStrings);

  const { language } = useContext(AppContext);

  const [error, setError] = useState("");
  const [filtersVersion, setFiltersVersion] = useState(1);
  const [filters, setFilters] = useState(DEFAULT_MONITORING_FILTERS);
  const [pagination, setPagination] = useState(DEFAULT_TABLE_PAGINATION);
  const [sorting, setSorting] = useState(DEFAULT_MONITORING_MEDIA_SORTING);

  const {
    isLoading: monitoringLoading,
    isExporting,
    mediaList,
    advertiserList,
    getMediaList,
    getAdvertiserList,
    exportMediaList,
    exportAdvertiserList,
    resetLists,
  } = useMonitoringView(true);

  const viewList = filters.view === "media" ? mediaList : advertiserList;
  const getList = filters.view === "media" ? getMediaList : getAdvertiserList;
  const sortOptions =
    filters.view === "media"
      ? DEFAULT_MONITORING_MEDIA_SORTING
      : DEFAULT_MONITORING_ADVERTISER_SORTING;
  const exportList =
    filters.view === "media" ? exportMediaList : exportAdvertiserList;

  const viewData = useMemo(() => {
    return viewList?.data;
  }, [viewList]);

  const mediaColumns = [
    {
      field: "mediaId",
      headerName: monitoringStrings.mediaId,
      minWidth: 150,
    },
    {
      field: "media",
      headerName: monitoringStrings.mediaName,
      minWidth: 200,
    },
  ];

  const advertiserColumns = [
    {
      field: "advertiser",
      headerName: monitoringStrings.advertiserLabel,
      minWidth: 200,
    },
  ];

  const columns = useMemo(() => {
    const cols =
      filters.view === "media" ? [...mediaColumns] : [...advertiserColumns];

    Object.entries(viewData?.[0]?.series ?? {}).forEach((serie) => {
      cols.push({
        field: serie[0],
        headerName: serie[1],
        minWidth: 100,
      });
    });

    return cols;
  }, [viewData, language]);

  const handleCloseError = () => setError(false);

  const handleExport = async () => {
    exportList({
      ...filters,
      ...sorting,
      userLanguage: language,
    })
      .then((data) => {
        downloadFile(
          `bis-${monitoringStrings.title.replaceAll(" ", "-").toLowerCase()}`,
          "xlsx",
          data,
        );
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleResetFilters = () => {
    setFilters(DEFAULT_MONITORING_FILTERS);
    setFiltersVersion((prev) => prev + 1);
  };

  const handleSearch = async () => {
    resetLists();

    await getList({
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      ...filters,
      ...sortOptions,
      userLanguage: language,
    });
    setPagination({ ...pagination, pageNumber: 0 });
    setSorting(sortOptions);
  };

  const handleSort = async (columnId, direction) => {
    if (!viewData.length) {
      return;
    }

    const sortColumn = columnId.charAt(0).toUpperCase() + columnId.slice(1);

    await getList({
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      ...filters,
      ...sorting,
      userLanguage: language,
      sortOption: { sortColumn, direction },
    });
    setPagination({ ...pagination, pageNumber: 0 });
    setSorting({ ...sorting, sortOption: { sortColumn, direction } });
  };

  const handleChangePage = async (event, newPage) => {
    await getList({
      pagination: {
        ...pagination,
        pageNumber: newPage + 1,
      },
      ...filters,
      ...sorting,
      userLanguage: language,
    });
    setPagination({ ...pagination, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = async (event) => {
    const value = parseInt(event.target.value, 10);
    await getList({
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      pageSize: value,
      ...filters,
      ...sorting,
      userLanguage: language,
    });
    setPagination({ ...pagination, pageNumber: 0, pageSize: value });
  };

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {monitoringStrings?.errors?.[error]}
      </AppAlert>

      <Grid container direction="column" height="100%">
        <Grid xs={12} container justifyContent="space-between" minHeight="32px">
          <Typography>{monitoringStrings.title}</Typography>
          <Grid container>
            <Button
              startIcon={
                isExporting ? (
                  <CircularProgress size={18} sx={{ opacity: 0.5 }} />
                ) : (
                  <Download />
                )
              }
              variant="outlined"
              disabled={!viewData?.length || isExporting}
              onClick={handleExport}
              sx={{ ml: 2 }}
            >
              {monitoringStrings.exportButton}
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={handleResetFilters}
              startIcon={<Refresh sx={{ transform: "scaleX(-1)" }} />}
              variant="text"
            >
              {monitoringStrings.resetFiltersButton}
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} paddingY={3}>
          <MonitoringFilters
            key={filtersVersion}
            filters={filters}
            setFilters={setFilters}
            onSearch={handleSearch}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          {viewData && (
            <DataTable
              columns={columns}
              rows={viewData || []}
              page={pagination.pageNumber}
              rowsPerPage={pagination.pageSize}
              totalRecords={viewList.totalRecords}
              allowSelection={false}
              sortBy={
                sorting.sortOption.sortColumn.charAt(0).toLowerCase() +
                sorting.sortOption.sortColumn.slice(1)
              }
              sortDirection={sorting?.sortOption?.direction}
              sortableColumns={columns.map((item) => item.field)}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onSort={handleSort}
            />
          )}
        </Grid>
      </Grid>

      <Backdrop
        open={monitoringLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};
